import langManager from "../../Lang/langManager";
import menuConfig from "../../Configurations/header";
import "./Header.css";
import { useState } from "react";

function Header() {
  const [lang, setLang] = useState(langManager());

  return (
    <header className="header" data-aos="fade-bottom" data-aos-delay="200">
      <nav className="nav-container container">
        <div className="navigation-brand">
          <img src={menuConfig.Logo}></img>
        </div>
        <div>
          <ul className="header-navigation">
            {menuConfig.Items.map((item) => {
              return (
                <li className="navigation-item">
                  <a href={`#${item.tag}`} className="navigation-link">
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
