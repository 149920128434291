import Header from "../../Components/Header/Header";
import WelcomeSlider from "../../Components/WelcomeSlider/WelcomeSlider";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Counters from "../../Components/Counters/Counters";
import OurTeam from "../../Components/OurTeam/OurTeam";
import Services from "../../Components/Services/Services";
import ContactInfo from "../../Components/ContactInfo/ContactInfo";
import Location from "../../Components/Location/Location";
import Footer from "../../Components/Footer/Footer";
import "./App.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();

  return (
    <div className="App">
      <Header />
      <WelcomeSlider />
      <AboutUs />
      <Counters />
      <Services />
      <ContactInfo />
      <Location />
      <Footer />
    </div>
  );
}

export default App;
