import firstImage from "../Assets/welcome-slider/1.jpg";
import secondImage from "../Assets/welcome-slider/2.jpg";
import thirdImage from "../Assets/welcome-slider/3.jpg";

export default {
  Menu: {
    Item1: "Services",
    Item2: "Work",
    Item3: "Career",
    Item4: "Contact",
    Item5: "About",
  },
  WelcomeSlider: [
    {
      title: "Транспортування квітів",
      description:
        "Ми пропонуємо міжнародне, міжрегіональне та міжміське транспортування квітів рефрижератором, з митним очищенням та повним документальним оформленням.",
      image: firstImage,
      textAligh: "left",
      position: "top",
    },
    {
      title: "Експорт",
      description:
        "Компанія UFG Export забезпечує транспортування свіжозрізаних квітів. Ми доставляємо квіти з Польщи, Нідерландів та інших країн Європи з повним документальним супроводом та необхідним комплексом послуг.",
      image: thirdImage,
      textAligh: "left",
      position: "top",
    },
    {
      title: "Делікатний Груз",
      description:
        "Квіти дуже делікатний вантаж. Вони не терплять недбалого відношення та помилок у транспортуванні. Ціна таких прорахунків – втрачені гроші. Збереження зовнішнього вигляду та повна неушкодженість ці якості можна гарантувати, якщо налагодити логістичні процеси відповідно до норм.",
      image: secondImage,
      textAligh: "left",
      position: "bottom",
    },
  ],
};