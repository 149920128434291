import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faClock } from "@fortawesome/free-regular-svg-icons";

import langManager from "../../Lang/langManager";
import "./ContactInfo.css";

export default function ContactInfo() {
  return (
    <section className="contacts-section" id="Contacts">
      <div className="contacts-section-title">Contact Info</div>

      <div className="contacts-container container">
        <div className="contact-info" data-aos="fade-up" data-aos-delay="100">
          <div className="contact-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <div className="contact-title">Location</div>
          <div className="contact-description">
            Poland
            <br />
            Netherlands
            <br />
            Europe
          </div>
        </div>

        <div className="contact-info" data-aos="fade-up" data-aos-delay="200">
          <div className="contact-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <div className="contact-title">E-mail</div>
          <div className="contact-description">
            ufg.export@gmail.com
          </div>
        </div>

        <div className="contact-info" data-aos="fade-up" data-aos-delay="300">
          <div className="contact-icon">
            <FontAwesomeIcon icon={faPhoneAlt} />
          </div>
          <div className="contact-title">Phone</div>
          <div className="contact-description">
            +380(67)344-85-43
            <br />
            +316 4490-5253 
          </div>
        </div>

        <div className="contact-info" data-aos="fade-up" data-aos-delay="400">
          <div className="contact-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="contact-title">Opening Hours</div>
          <div className="contact-description">
            Mon-Fri: 10:00am - 6:00pm
            <br />
            Sat-Sun: 11:00am - 4:00pm
          </div>
        </div>
      </div>
    </section>
  );
}