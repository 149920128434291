import { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import langManager from "../../Lang/langManager";
import "./WelcomeSlider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function WelcomeSlider() {
  const [lang, setLang] = useState(langManager());

  function getBgImage(src) {
    return {
      backgroundImage: `url('${src.image}')`,
      backgroundPosition: src.position
    };
  }

  return (
    <Carousel swipeable autoPlay showThumbs={false} swipeable={false} infiniteLoop={true}>
      {lang.WelcomeSlider.map((item) => {
        return (
          <section className="welcome-section" style={getBgImage(item)}>
            <div className={`welcome-info ${item.textAligh} container`}>
              <div className="welcome-text">
                <div className="welcome-title">{item.title}</div>
                <div className="welcome-description">{item.description}</div>
              </div>
            </div>
          </section>
        );
      })}
    </Carousel>
  );
}