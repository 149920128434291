import enUs from './en-us'
import uaUa from './ua-ua'

const currentLang = "en-us";
const defaultLang = enUs;

export default function getLang() {
    if (currentLang === "en-us") {
        return enUs;
    }

    if(currentLang === "ua-ua") {
        return uaUa;
    }

    return defaultLang;
}