import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTruck, faUserFriends, faTruckLoading } from "@fortawesome/free-solid-svg-icons";

import langManager from "../../Lang/langManager";
import "./Counters.css";

export default function Counters() {
  const [lang, setLang] = useState(langManager());

  function getBgImage(src) {
    return {
      backgroundImage: `url('${src}')`,
    };
  }

  return (
    <section className="counters-section">
      <div className="counters-container container">
        <div className="counter" data-aos="fade-up" data-aos-delay="100">
          <div className="counter-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="counter-info">
            <div className="counter-value">132</div>
            <div className="counter-description">Cliens</div>
          </div>
        </div>

        <div className="counter" data-aos="fade-up" data-aos-delay="200">
          <div className="counter-icon">
            <FontAwesomeIcon icon={faTruck} />
          </div>
          <div className="counter-info">
            <div className="counter-value">13</div>
            <div className="counter-description">Owned Vehicle</div>
          </div>
        </div>

        <div className="counter" data-aos="fade-up" data-aos-delay="300">
          <div className="counter-icon">
            <FontAwesomeIcon icon={faUserFriends} />
          </div>
          <div className="counter-info">
            <div className="counter-value">240</div>
            <div className="counter-description">People in team</div>
          </div>
        </div>

        <div className="counter" data-aos="fade-up" data-aos-delay="400">
          <div className="counter-icon">
            <FontAwesomeIcon icon={faTruckLoading} />
          </div>
          <div className="counter-info">
            <div className="counter-value">32 456+</div>
            <div className="counter-description">Deliveries Done</div>
          </div>
        </div>
      </div>
    </section>
  );
}
