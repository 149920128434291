import { useState } from "react";
import { Map, Marker } from "pigeon-maps";
import { maptiler } from 'pigeon-maps/providers'

import "./Location.css";

export default function Location() {
  const mapTilerProvider = maptiler('ZvAOTxocLznCdhCOJQDg', 'voyager');
  const color = `hsl(148, 100%, 30%)`

  return (
    <section className="location-section" data-aos="fade-up">
      <Map
        provider={mapTilerProvider}
        dprs={[1, 2]} // this provider supports HiDPI tiles
        height={500}
        defaultCenter={[52.213, 11.2794]}
        defaultZoom={4}
        mouseEvents={false}
        touchEvents={false}
      >
        <Marker width={60} anchor={[52.213, 5.5794]} color={color} />
        <Marker width={60} anchor={[52.100, 19.1451]} color={color} />
      </Map>
    </section>
  );
}