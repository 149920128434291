import { useState } from "react";

import langManager from "../../Lang/langManager";
import "./Services.css";

export default function Services() {
  const [lang, setLang] = useState(langManager());

  return (
    <section
      className="services-section"
      data-aos-delay="100"
      data-aos="fade-right"
      id="Services"
    >
      <div className="container">
        <div className="services-container">
          <div className="service">
            <div className="service-icon"></div>
            <div className="service-title">Кваліфіковані фахівці</div>
            <div className="service-description">
              Регулярне навчання логістів за внутрішньою системою підвищення
              кваліфікації. Логіст, що супроводжує проект, проходить відповідне
              навчання згідно з запитом.
            </div>
          </div>

          <div className="service">
            <div className="service-icon"></div>
            <div className="service-title">Комплексний підхід</div>
            <div className="service-description">
              Клієнт отримує повний супровід, контроль та прозорість згідно зі
              замовленими послугами та звільняється від будь-яких турбот,
              пов’язаних з перевезеннями.
            </div>
          </div>

          <div className="service">
            <div className="service-icon"></div>
            <div className="service-title">Персоналізованість</div>
            <div className="service-description">
              Індивідуальний підхід до кожного клієнта та гнучкі рішення
              гарантують найвищі показники ефективної співпраці з нашими
              партнерами.
            </div>
          </div>

          <div className="service">
            <div className="service-icon"></div>
            <div className="service-title">Професійна етика</div>
            <div className="service-description">
              Правила компанії ґрунтуються на безперервній роботі, відданості
              справі та дотриманні всіх умов в складених контрактах.
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}
