import logo from "../Assets/logo-light-header.svg";

export default {
    Logo: logo,
    Items: [
        {
            id: 1,
            name: "Про нас",
            tag: "About"
        },
        {
            id: 2,
            name: "Переваги",
            tag: "Services"
        },
        {
            id: 3,
            name: "Контакти",
            tag: "Contacts"
        }
    ]
};