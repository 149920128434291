import { useState } from "react";
import menuConfig from "../../Configurations/header";

import logo from "../../Assets/logo-light.svg";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer-container container">
        <div className="logo-container">
          <img src={logo} className="footer-logo" />
        </div>

        <div className="footer-info-container">
          <div className="copirigh-text">&copy;2021 Ukrainian Flower Group</div>
          <div className="credits-text">All rights reserved</div>
        </div>

        <div className="footer-nav-container">
          <ul className="footer-navigation">
            {menuConfig.Items.map((item) => {
              return (
                <li className="navigation-item">
                  <a href={`#${item.tag}`} className="navigation-link">
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
}
