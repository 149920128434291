import { useState } from "react";

import videoMockImage from "../../Assets/video-mock.jpg";
import langManager from "../../Lang/langManager";
import "./AboutUs.css";

export default function WelcomeSlider() {
  const [lang, setLang] = useState(langManager());

  return (
    <section className="about-section" id="About">
      <div className="about-info">
        <div className="about-title">WELCOME TO OUR WEBSITE!</div>
        <div className="about-description">
          Компанія UFG export спеціалізується на транспортуванні квітів з Європи
          та інших континентів, зберігання вантажів, повний документальний
          супровід(митне оформлення та експортно-імпортні операції).
          <br />
          <br />
          UFG Export використовує кілька методів міжнародної доставки квітів.
          Але якщо говорити про доставку з Європи, найбільш затребуваною є
          автоперевезення. Ми налагодили схему завантажень, розробили регулярний
          графік руху автомобілів та принципи розробки пакету документів.
          Увесь період клієнту доступне відстеження вантажу та отримання
          інформації про терміни доставки.
        </div>
      </div>

      <div className="about-video">
        <img className="about-video-mock" src={videoMockImage} />
      </div>
    </section>
  );
}
